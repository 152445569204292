import React, { useState, useEffect } from "react"
import SearchForm from "./searchForm"
import SearchResults from "./searchResults"
import buildSearchIndex from "./buildSearchIndex"

// **************************************************************************
// Searches for term in module data
// Uses the Js Search library to parse through page query of all module data
// https://github.com/bvaughn/js-search
// https://www.gatsbyjs.com/docs/adding-search-with-js-search/#js-search-with-a-small-to-medium-dataset
// **************************************************************************

const Search = ({allData}) => {
  const [searchTerm, setSearchTerm] = useState("")
  const [search, setSearch] = useState(null)
  const [searchResultData, setSearchResultData] = useState([])

  useEffect(() => {
    buildSearchIndex({ allData, setSearch })
  }, [])

  const headerColors = allData.stepColors.acfOptionsCompassusAppSettings.globalOptions.stepColors;

  return (
    <div className="search-container">
      <SearchForm search={search} setSearchTerm={setSearchTerm} setSearchResultData={setSearchResultData} />
     
      <div className={"loader " + (searchTerm && !searchResultData ? "active" : "")}>
        <h3>Loading results for "{searchTerm}" <span>.</span><span>.</span><span>.</span></h3>
      </div>  

      {searchTerm && searchResultData && <SearchResults searchTerm={searchTerm} searchResultData={searchResultData} headerColors={headerColors}/>}
    </div>
  )
}
export default Search