// *************************************************************************************************
// accepts param of all module data and returns a slide object array to use as structured search data
// also formats to make info easier to display in search results
// *************************************************************************************************

const formatSearchSourceData = ({ allData }) => {
  const slideObjectsArray = []

  for (let [pathwayKey, pathway] of Object.entries(allData.allWpPathway.edges)) {
    let pathwayUri = pathway.post.uri
    let pathwayTitle = pathway.post.uri.replace(/-/g, " ").replace(/\//g, "")

    for (let [pathwayFieldKey, pathwayField] of Object.entries(pathway.post.pathwayFields)) {
      let stepSlug = pathwayField.fieldGroupName.replace(/_/g, "-")
      let stepTitle = pathwayField.fieldGroupName.replace(/_/g, " ").replace(/\//g, "")

      if (pathwayField.stepBlock){
        for (let [stepBlockKey, stepBlock] of Object.entries(pathwayField.stepBlock)) {
          if (stepBlock.modules) {
            for (let [modulesKey, modules] of Object.entries(stepBlock.modules)) {      
              for (let [moduleKey, module] of Object.entries(modules)) {  
                let moduleUri = module.uri
                let moduleTitle = module.uri.replace(/-/g, " ").replace(/\//g, "")
                let databaseId = module.databaseId
  
                if (module.moduleFields?.slideRepeater) {    
                  let slides = Object.entries(module.moduleFields.slideRepeater)
  
                  for (let i = 0; i < slides.length; i++) {                 
                    let slide = slides[i][1]?.slide
                    let slideNumber = i+1
  
                    slideObjectsArray.push({
                      "path": pathwayUri + stepSlug + moduleUri + "-" + databaseId + "/?slide-number=" + slideNumber,
                      "pathwayTitle": pathwayTitle,
                      "stepTitle": stepTitle,
                      "moduleTitle": moduleTitle,
                      "slide": slide,
                      "slideNumber": slideNumber
                    })
                  }
                } 
              }
            }
          }
        }
      }
    }
  }
  return slideObjectsArray
}

export default formatSearchSourceData
