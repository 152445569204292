import * as JsSearch from "js-search"
import formatSearchSourceData from "./formatSearchSourceData"

  const buildSearchIndex = ({ allData, setSearch }) => {
    // uses "path" as unique id/index for each slide
    const search = new JsSearch.Search("path")

    // ****************************************************
    // configures search strategy, sanitize, 
    // removes stop words, and sets search source data 
    // ****************************************************
    var stemmer = require('porter-stemmer').stemmer
    
    search.indexStrategy = new JsSearch.ExactWordIndexStrategy()
    search.sanitizer = new JsSearch.LowerCaseSanitizer()  
    search.tokenizer = new JsSearch.StopWordsTokenizer(new JsSearch.SimpleTokenizer());
    search.tokenizer = new JsSearch.StemmingTokenizer( 
        stemmer, // Function should accept a string param and return a string
        new JsSearch.SimpleTokenizer());
    search.addDocuments(formatSearchSourceData({ allData }))

    // *******************************
    // Builds search index attributes
    // *******************************

    search.addIndex("moduleTitle")

    // ---- side header & textBlock
    search.addIndex(["slide", "0", "header"])
    search.addIndex(["slide", "1", "header"])
    search.addIndex(["slide", "2", "header"])
    search.addIndex(["slide", "3", "header"])
    search.addIndex(["slide", "4", "header"])
    search.addIndex(["slide", "5", "header"])
    search.addIndex(["slide", "0", "textBlock"])
    search.addIndex(["slide", "1", "textBlock"])
    search.addIndex(["slide", "2", "textBlock"])
    search.addIndex(["slide", "3", "textBlock"])
    search.addIndex(["slide", "4", "textBlock"])
    search.addIndex(["slide", "5", "textBlock"])
    
    // ---- keyConceptModal (modalHeader & modalBody)
    search.addIndex(["slide", "0", "keyConceptModal", "modalHeader"])
    search.addIndex(["slide", "1", "keyConceptModal", "modalHeader"])
    search.addIndex(["slide", "2", "keyConceptModal", "modalHeader"])
    search.addIndex(["slide", "3", "keyConceptModal", "modalHeader"])
    search.addIndex(["slide", "4", "keyConceptModal", "modalHeader"])
    search.addIndex(["slide", "5", "keyConceptModal", "modalHeader"])
    search.addIndex(["slide", "6", "keyConceptModal", "modalHeader"])
    search.addIndex(["slide", "7", "keyConceptModal", "modalHeader"])
    search.addIndex(["slide", "8", "keyConceptModal", "modalHeader"])
    search.addIndex(["slide", "9", "keyConceptModal", "modalHeader"])
    search.addIndex(["slide", "10", "keyConceptModal", "modalHeader"])

    search.addIndex(["slide", "0", "keyConceptModal", "modalBody"])
    search.addIndex(["slide", "1", "keyConceptModal", "modalBody"])
    search.addIndex(["slide", "2", "keyConceptModal", "modalBody"])
    search.addIndex(["slide", "3", "keyConceptModal", "modalBody"])
    search.addIndex(["slide", "4", "keyConceptModal", "modalBody"])
    search.addIndex(["slide", "5", "keyConceptModal", "modalBody"])
    search.addIndex(["slide", "6", "keyConceptModal", "modalBody"])
    search.addIndex(["slide", "7", "keyConceptModal", "modalBody"])
    search.addIndex(["slide", "8", "keyConceptModal", "modalBody"])
    search.addIndex(["slide", "9", "keyConceptModal", "modalBody"])
    search.addIndex(["slide", "10", "keyConceptModal", "modalBody"])

    // ---- accordionSingular (accordionHeader & accordionBody)
    search.addIndex(["slide", "0", "accordionSingular", "accordionHeader"])
    search.addIndex(["slide", "1", "accordionSingular", "accordionHeader"])
    search.addIndex(["slide", "2", "accordionSingular", "accordionHeader"])
    search.addIndex(["slide", "3", "accordionSingular", "accordionHeader"])
    search.addIndex(["slide", "4", "accordionSingular", "accordionHeader"])
    search.addIndex(["slide", "5", "accordionSingular", "accordionHeader"])
    search.addIndex(["slide", "6", "accordionSingular", "accordionHeader"])
    search.addIndex(["slide", "7", "accordionSingular", "accordionHeader"])
    search.addIndex(["slide", "8", "accordionSingular", "accordionHeader"])
    search.addIndex(["slide", "9", "accordionSingular", "accordionHeader"])
    search.addIndex(["slide", "10", "accordionSingular", "accordionHeader"])

    search.addIndex(["slide", "0", "accordionSingular", "accordionBody"])
    search.addIndex(["slide", "1", "accordionSingular", "accordionBody"])
    search.addIndex(["slide", "2", "accordionSingular", "accordionBody"])
    search.addIndex(["slide", "3", "accordionSingular", "accordionBody"])
    search.addIndex(["slide", "4", "accordionSingular", "accordionBody"])
    search.addIndex(["slide", "5", "accordionSingular", "accordionBody"])
    search.addIndex(["slide", "6", "accordionSingular", "accordionBody"])
    search.addIndex(["slide", "7", "accordionSingular", "accordionBody"])
    search.addIndex(["slide", "8", "accordionSingular", "accordionBody"])
    search.addIndex(["slide", "9", "accordionSingular", "accordionBody"])
    search.addIndex(["slide", "10", "accordionSingular", "accordionBody"])

    // ---- accordionNested (accordionLevelOneHeader & accordionLevelOneBody)
    search.addIndex(["slide", "0", "accordionNested", "0", "accordionLevelOneHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelOneHeader"])
    search.addIndex(["slide", "2", "accordionNested", "0", "accordionLevelOneHeader"])
    search.addIndex(["slide", "3", "accordionNested", "0", "accordionLevelOneHeader"])
    search.addIndex(["slide", "4", "accordionNested", "0", "accordionLevelOneHeader"])
    search.addIndex(["slide", "5", "accordionNested", "0", "accordionLevelOneHeader"])
    search.addIndex(["slide", "0", "accordionNested", "0", "accordionLevelOneBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelOneBody"])
    search.addIndex(["slide", "2", "accordionNested", "0", "accordionLevelOneBody"])
    search.addIndex(["slide", "3", "accordionNested", "0", "accordionLevelOneBody"])
    search.addIndex(["slide", "4", "accordionNested", "0", "accordionLevelOneBody"])
    search.addIndex(["slide", "5", "accordionNested", "0", "accordionLevelOneBody"])

    // ---- accordionNested (accordionLevelTwoHeader)
    search.addIndex(["slide", "0", "accordionNested", "0", "accordionLevelTwoRepeater", "0", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "0", "accordionNested", "0", "accordionLevelTwoRepeater", "1", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "0", "accordionNested", "0", "accordionLevelTwoRepeater", "2", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "0", "accordionNested", "0", "accordionLevelTwoRepeater", "3", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "0", "accordionNested", "0", "accordionLevelTwoRepeater", "4", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "0", "accordionNested", "0", "accordionLevelTwoRepeater", "5", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "0", "accordionNested", "0", "accordionLevelTwoRepeater", "6", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "0", "accordionNested", "0", "accordionLevelTwoRepeater", "7", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "0", "accordionNested", "0", "accordionLevelTwoRepeater", "8", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "0", "accordionNested", "0", "accordionLevelTwoRepeater", "9", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "0", "accordionNested", "0", "accordionLevelTwoRepeater", "10", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "0", "accordionNested", "0", "accordionLevelTwoRepeater", "11", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "0", "accordionNested", "0", "accordionLevelTwoRepeater", "12", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "0", "accordionNested", "0", "accordionLevelTwoRepeater", "13", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "0", "accordionNested", "0", "accordionLevelTwoRepeater", "14", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "0", "accordionNested", "0", "accordionLevelTwoRepeater", "15", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])

    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "0", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "1", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "2", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "3", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "4", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "5", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "6", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "7", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "8", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "9", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "10", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "11", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "12", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "13", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "14", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "15", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    
    search.addIndex(["slide", "2", "accordionNested", "0", "accordionLevelTwoRepeater", "0", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "2", "accordionNested", "0", "accordionLevelTwoRepeater", "1", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "2", "accordionNested", "0", "accordionLevelTwoRepeater", "2", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "2", "accordionNested", "0", "accordionLevelTwoRepeater", "3", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "2", "accordionNested", "0", "accordionLevelTwoRepeater", "4", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "2", "accordionNested", "0", "accordionLevelTwoRepeater", "5", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "2", "accordionNested", "0", "accordionLevelTwoRepeater", "6", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "2", "accordionNested", "0", "accordionLevelTwoRepeater", "7", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "2", "accordionNested", "0", "accordionLevelTwoRepeater", "8", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "2", "accordionNested", "0", "accordionLevelTwoRepeater", "9", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "2", "accordionNested", "0", "accordionLevelTwoRepeater", "10", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "2", "accordionNested", "0", "accordionLevelTwoRepeater", "11", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "2", "accordionNested", "0", "accordionLevelTwoRepeater", "12", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "2", "accordionNested", "0", "accordionLevelTwoRepeater", "13", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "2", "accordionNested", "0", "accordionLevelTwoRepeater", "14", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
    search.addIndex(["slide", "2", "accordionNested", "0", "accordionLevelTwoRepeater", "15", "accordionLevelTwoGroup", "accordionLevelTwoHeader"])
      
    // ---- accordionNested (accordionLevelTwoBody)
    search.addIndex(["slide", "0", "accordionNested", "0", "accordionLevelTwoRepeater", "0", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "0", "accordionNested", "0", "accordionLevelTwoRepeater", "1", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "0", "accordionNested", "0", "accordionLevelTwoRepeater", "2", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "0", "accordionNested", "0", "accordionLevelTwoRepeater", "3", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "0", "accordionNested", "0", "accordionLevelTwoRepeater", "4", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "0", "accordionNested", "0", "accordionLevelTwoRepeater", "5", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "0", "accordionNested", "0", "accordionLevelTwoRepeater", "6", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "0", "accordionNested", "0", "accordionLevelTwoRepeater", "7", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "0", "accordionNested", "0", "accordionLevelTwoRepeater", "8", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "0", "accordionNested", "0", "accordionLevelTwoRepeater", "9", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "0", "accordionNested", "0", "accordionLevelTwoRepeater", "10", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "0", "accordionNested", "0", "accordionLevelTwoRepeater", "11", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "0", "accordionNested", "0", "accordionLevelTwoRepeater", "12", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "0", "accordionNested", "0", "accordionLevelTwoRepeater", "13", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "0", "accordionNested", "0", "accordionLevelTwoRepeater", "14", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "0", "accordionNested", "0", "accordionLevelTwoRepeater", "15", "accordionLevelTwoGroup", "accordionLevelTwoBody"])

    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "0", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "1", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "2", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "3", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "4", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "5", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "6", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "7", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "8", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "9", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "10", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "11", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "12", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "13", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "14", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "15", "accordionLevelTwoGroup", "accordionLevelTwoBody"])

    search.addIndex(["slide", "2", "accordionNested", "0", "accordionLevelTwoRepeater", "0", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "2", "accordionNested", "0", "accordionLevelTwoRepeater", "1", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "2", "accordionNested", "0", "accordionLevelTwoRepeater", "2", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "2", "accordionNested", "0", "accordionLevelTwoRepeater", "3", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "2", "accordionNested", "0", "accordionLevelTwoRepeater", "4", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "2", "accordionNested", "0", "accordionLevelTwoRepeater", "5", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "2", "accordionNested", "0", "accordionLevelTwoRepeater", "6", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "2", "accordionNested", "0", "accordionLevelTwoRepeater", "7", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "2", "accordionNested", "0", "accordionLevelTwoRepeater", "8", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "2", "accordionNested", "0", "accordionLevelTwoRepeater", "9", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "2", "accordionNested", "0", "accordionLevelTwoRepeater", "10", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "2", "accordionNested", "0", "accordionLevelTwoRepeater", "11", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "2", "accordionNested", "0", "accordionLevelTwoRepeater", "12", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "2", "accordionNested", "0", "accordionLevelTwoRepeater", "13", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "2", "accordionNested", "0", "accordionLevelTwoRepeater", "14", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    search.addIndex(["slide", "2", "accordionNested", "0", "accordionLevelTwoRepeater", "15", "accordionLevelTwoGroup", "accordionLevelTwoBody"])
    
    // ---- accordionNested (accordionLevelThreeHeader)
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "0", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "0", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "0", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "1", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "0", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "2", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "0", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "3", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "0", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "4", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "0", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "5", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "0", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "6", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "0", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "7", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "0", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "8", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "0", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "9", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "0", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "10", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])

    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "1", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "0", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "1", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "1", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "1", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "2", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "1", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "3", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "1", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "4", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "1", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "5", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "1", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "6", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "1", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "7", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "1", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "8", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "1", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "9", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "1", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "10", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])

    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "2", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "0", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "2", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "1", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "2", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "2", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "2", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "3", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "2", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "4", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "2", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "5", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "2", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "6", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "2", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "7", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "2", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "8", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "2", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "9", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "2", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "10", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])

    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "3", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "0", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "3", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "1", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "3", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "2", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "3", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "3", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "3", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "4", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "3", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "5", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "3", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "6", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "3", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "7", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "3", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "8", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "3", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "9", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "3", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "10", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])

    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "4", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "0", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "4", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "1", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "4", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "2", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "4", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "3", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "4", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "4", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "4", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "5", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "4", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "6", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "4", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "7", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "4", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "8", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "4", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "9", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "4", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "10", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])

    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "5", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "0", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "5", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "1", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "5", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "2", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "5", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "3", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "5", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "4", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "5", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "5", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "5", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "6", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "5", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "7", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "5", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "8", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "5", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "9", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "5", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "10", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])

    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "6", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "0", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "6", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "1", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "6", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "2", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "6", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "3", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "6", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "4", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "6", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "5", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "6", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "6", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "6", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "7", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "6", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "8", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "6", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "9", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "6", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "10", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])

    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "7", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "0", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "7", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "1", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "7", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "2", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "7", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "3", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "7", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "4", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "7", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "5", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "7", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "6", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "7", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "7", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "7", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "8", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "7", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "9", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "7", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "10", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])

    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "8", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "0", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "8", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "1", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "8", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "2", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "8", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "3", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "8", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "4", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "8", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "5", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "8", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "6", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "8", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "7", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "8", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "8", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "8", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "9", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "8", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "10", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])

    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "9", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "0", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "9", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "1", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "9", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "2", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "9", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "3", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "9", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "4", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "9", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "5", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "9", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "6", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "9", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "7", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "9", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "8", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "9", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "9", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "9", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "10", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])

    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "10", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "0", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "10", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "1", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "10", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "2", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "10", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "3", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "10", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "4", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "10", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "5", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "10", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "6", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "10", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "7", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "10", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "8", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "10", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "9", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "10", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "10", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])

    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "11", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "0", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "11", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "1", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "11", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "2", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "11", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "3", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "11", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "4", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "11", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "5", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "11", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "6", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "11", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "7", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "11", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "8", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "11", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "9", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "11", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "10", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])

    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "12", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "0", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "12", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "1", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "12", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "2", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "12", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "3", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "12", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "4", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "12", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "5", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "12", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "6", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "12", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "7", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "12", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "8", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "12", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "9", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "12", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "10", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])

    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "13", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "0", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "13", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "1", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "13", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "2", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "13", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "3", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "13", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "4", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "13", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "5", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "13", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "6", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "13", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "7", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "13", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "8", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "13", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "9", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "13", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "10", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])

    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "14", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "0", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "14", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "1", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "14", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "2", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "14", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "3", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "14", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "4", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "14", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "5", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "14", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "6", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "14", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "7", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "14", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "8", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "14", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "9", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "14", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "10", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])

    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "15", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "0", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "15", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "1", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "15", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "2", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "15", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "3", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "15", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "4", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "15", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "5", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "15", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "6", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "15", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "7", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "15", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "8", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "15", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "9", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "15", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "10", "accordionLevelThreeGroup", "accordionLevelThreeHeader"])
 
    // ---- accordionNested (accordionLevelThreeBody)
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "0", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "0", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "0", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "1", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "0", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "2", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "0", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "3", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "0", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "4", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "0", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "5", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "0", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "6", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "0", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "7", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "0", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "8", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "0", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "9", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "0", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "10", "accordionLevelThreeGroup", "accordionLevelThreeBody"])

    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "1", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "0", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "1", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "1", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "1", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "2", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "1", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "3", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "1", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "4", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "1", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "5", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "1", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "6", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "1", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "7", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "1", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "8", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "1", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "9", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "1", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "10", "accordionLevelThreeGroup", "accordionLevelThreeBody"])

    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "2", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "0", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "2", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "1", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "2", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "2", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "2", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "3", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "2", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "4", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "2", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "5", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "2", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "6", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "2", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "7", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "2", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "8", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "2", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "9", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "2", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "10", "accordionLevelThreeGroup", "accordionLevelThreeBody"])

    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "3", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "0", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "3", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "1", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "3", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "2", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "3", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "3", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "3", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "4", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "3", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "5", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "3", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "6", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "3", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "7", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "3", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "8", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "3", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "9", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "3", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "10", "accordionLevelThreeGroup", "accordionLevelThreeBody"])

    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "4", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "0", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "4", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "1", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "4", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "2", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "4", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "3", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "4", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "4", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "4", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "5", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "4", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "6", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "4", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "7", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "4", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "8", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "4", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "9", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "4", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "10", "accordionLevelThreeGroup", "accordionLevelThreeBody"])

    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "5", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "0", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "5", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "1", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "5", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "2", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "5", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "3", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "5", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "4", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "5", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "5", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "5", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "6", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "5", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "7", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "5", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "8", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "5", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "9", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "5", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "10", "accordionLevelThreeGroup", "accordionLevelThreeBody"])

    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "6", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "0", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "6", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "1", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "6", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "2", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "6", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "3", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "6", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "4", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "6", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "5", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "6", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "6", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "6", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "7", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "6", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "8", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "6", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "9", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "6", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "10", "accordionLevelThreeGroup", "accordionLevelThreeBody"])

    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "7", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "0", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "7", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "1", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "7", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "2", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "7", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "3", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "7", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "4", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "7", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "5", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "7", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "6", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "7", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "7", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "7", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "8", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "7", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "9", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "7", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "10", "accordionLevelThreeGroup", "accordionLevelThreeBody"])

    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "8", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "0", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "8", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "1", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "8", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "2", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "8", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "3", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "8", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "4", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "8", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "5", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "8", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "6", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "8", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "7", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "8", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "8", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "8", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "9", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "8", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "10", "accordionLevelThreeGroup", "accordionLevelThreeBody"])

    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "9", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "0", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "9", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "1", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "9", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "2", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "9", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "3", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "9", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "4", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "9", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "5", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "9", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "6", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "9", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "7", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "9", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "8", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "9", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "9", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "9", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "10", "accordionLevelThreeGroup", "accordionLevelThreeBody"])

    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "10", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "0", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "10", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "1", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "10", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "2", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "10", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "3", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "10", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "4", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "10", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "5", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "10", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "6", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "10", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "7", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "10", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "8", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "10", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "9", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "10", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "10", "accordionLevelThreeGroup", "accordionLevelThreeBody"])

    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "11", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "0", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "11", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "1", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "11", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "2", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "11", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "3", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "11", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "4", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "11", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "5", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "11", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "6", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "11", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "7", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "11", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "8", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "11", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "9", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "11", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "10", "accordionLevelThreeGroup", "accordionLevelThreeBody"])

    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "12", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "0", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "12", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "1", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "12", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "2", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "12", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "3", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "12", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "4", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "12", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "5", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "12", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "6", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "12", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "7", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "12", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "8", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "12", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "9", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "12", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "10", "accordionLevelThreeGroup", "accordionLevelThreeBody"])

    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "13", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "0", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "13", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "1", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "13", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "2", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "13", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "3", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "13", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "4", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "13", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "5", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "13", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "6", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "13", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "7", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "13", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "8", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "13", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "9", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "13", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "10", "accordionLevelThreeGroup", "accordionLevelThreeBody"])

    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "14", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "0", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "14", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "1", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "14", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "2", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "14", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "3", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "14", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "4", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "14", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "5", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "14", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "6", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "14", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "7", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "14", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "8", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "14", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "9", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "14", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "10", "accordionLevelThreeGroup", "accordionLevelThreeBody"])

    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "15", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "0", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "15", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "1", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "15", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "2", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "15", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "3", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "15", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "4", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "15", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "5", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "15", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "6", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "15", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "7", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "15", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "8", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "15", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "9", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    search.addIndex(["slide", "1", "accordionNested", "0", "accordionLevelTwoRepeater", "15", "accordionLevelTwoGroup", "accordionLevelThreeRepeater", "10", "accordionLevelThreeGroup", "accordionLevelThreeBody"])
    
    setSearch(search)
  }

export default buildSearchIndex
