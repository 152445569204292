import React, { useState } from "react"
import SearchIcon from "../../../content/assets/search.svg"

// ********************************************************************************************
// Search Form to manage input for js-search library 
// accepts parameters: 
// search {obj}, setSearchTerm {function}, setSearchResultData {function}
// ********************************************************************************************

const SearchForm = ({ search, setSearchTerm, setSearchResultData }) => {
  const [inputValue, setInputValue] = useState("")

  const handleSubmit = e => {
    e.preventDefault()

    if (inputValue) {
      const cleanInputValue = inputValue.replace(/(<([^>]+)>|\W)/gi, " ").trim()
      const queryResult = search.search(cleanInputValue)
      setSearchTerm(cleanInputValue)
      setSearchResultData(queryResult)
    } else {
      setSearchTerm("")
    }
  }

  return (
    <form className="search-form" role="search" onSubmit={handleSubmit}>
      <input
        id="search"
        className="search-form__input"
        type="search"
        placeholder="Search..."
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        autoComplete="off"
        autoFocus="on"
      />
      <button className="search-form__submit_button button button--square" type="submit"><SearchIcon /></button>
    </form>
  )
}
export default SearchForm
