import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/page-components/header"
import NavBar from "../components/global-components/navbar"
import Search from "../components/search-components/search"

const SearchPage = ({data}) => {

  return (
    <>  
      <Layout>
        <Header />
        <Seo title="Compassus Search Page" />
        <section className="search-section">
          <h2>Search Modules</h2>
          <Search allData={data} />
        </section>
      </Layout>
      <NavBar/>
    </>
  )
}

export default SearchPage

// page query to include all module data use in search
export const pageQuery = graphql`
  query getAllModules {
    allWpPathway {
      edges {
        post: node {
          uri
          pathwayFields {
            overview {
              fieldGroupName
              stepBlock {
                ... on WpPathway_Pathwayfields_Overview_stepBlock {
                  modules {
                    module {
                      ... on WpModule {
                        id
                        databaseId
                        uri
                        moduleFields {
                          mediaType
                          prettyTitle
                          slideRepeater {
                            slide {
                              ... on WpModule_Modulefields_slideRepeater_Slide_Header {
                                header
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_TextBlock {
                                textBlock
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_Image {
                                image {
                                  sourceUrl
                                  publicUrl
                                  altText
                                  caption
                                  link
                                }
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_Embed {
                                embed
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_Callout {
                                callout
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_KeyConceptModal {
                                keyConceptModal {
                                  modalHeader
                                  modalBody
                                  modalButtonIcon {
                                    sourceUrl
                                    publicUrl
                                    altText
                                    caption
                                    link
                                  }
                                  modalButtonHeader
                                }
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_Accordion {
                                accordionSingular {
                                  accordionHeader
                                  accordionBody
                                }
                              }

                              ... on WpModule_Modulefields_slideRepeater_Slide_NestedAccordion {
                                accordionNested {
                                  accordionLevelOneHeader
                                  accordionLevelOneBody
                                  accordionLevelTwoRepeater {
                                    accordionLevelTwoGroup {
                                      accordionLevelTwoHeader
                                      accordionLevelTwoBody
                                      accordionLevelThreeRepeater {
                                        accordionLevelThreeGroup {
                                          accordionLevelThreeHeader
                                          accordionLevelThreeBody
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_Button {
                                buttonGroup {
                                  buttonText
                                  buttonLink
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            rightMedsRightTime {
              fieldGroupName
              stepBlock {
                ... on WpPathway_Pathwayfields_RightMedsRightTime_stepBlock {
                  modules {
                    module {
                      ... on WpModule {
                        id
                        databaseId
                        uri
                        moduleFields {
                          mediaType
                          prettyTitle
                          slideRepeater {
                            slide {
                              ... on WpModule_Modulefields_slideRepeater_Slide_Header {
                                header
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_TextBlock {
                                textBlock
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_Image {
                                image {
                                  sourceUrl
                                  publicUrl
                                  altText
                                  caption
                                  link
                                }
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_Embed {
                                embed
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_Callout {
                                callout
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_KeyConceptModal {
                                keyConceptModal {
                                  modalHeader
                                  modalBody
                                  modalButtonIcon {
                                    sourceUrl
                                    publicUrl
                                    altText
                                    caption
                                    link
                                  }
                                  modalButtonHeader
                                }
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_Accordion {
                                accordionSingular {
                                  accordionHeader
                                  accordionBody
                                }
                              }

                              ... on WpModule_Modulefields_slideRepeater_Slide_NestedAccordion {
                                accordionNested {
                                  accordionLevelOneHeader
                                  accordionLevelOneBody
                                  accordionLevelTwoRepeater {
                                    accordionLevelTwoGroup {
                                      accordionLevelTwoHeader
                                      accordionLevelTwoBody
                                      accordionLevelThreeRepeater {
                                        accordionLevelThreeGroup {
                                          accordionLevelThreeHeader
                                          accordionLevelThreeBody
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_Button {
                                buttonGroup {
                                  buttonText
                                  buttonLink
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            totalPainManagement {
              fieldGroupName
              stepBlock {
                ... on WpPathway_Pathwayfields_TotalPainManagement_stepBlock {
                  modules {
                    module {
                      ... on WpModule {
                        id
                        databaseId
                        uri
                        moduleFields {
                          mediaType
                          prettyTitle
                          slideRepeater {
                            slide {
                              ... on WpModule_Modulefields_slideRepeater_Slide_Header {
                                header
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_TextBlock {
                                textBlock
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_Image {
                                image {
                                  sourceUrl
                                  publicUrl
                                  altText
                                  caption
                                  link
                                }
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_Embed {
                                embed
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_Callout {
                                callout
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_KeyConceptModal {
                                keyConceptModal {
                                  modalHeader
                                  modalBody
                                  modalButtonIcon {
                                    sourceUrl
                                    publicUrl
                                    altText
                                    caption
                                    link
                                  }
                                  modalButtonHeader
                                }
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_Accordion {
                                accordionSingular {
                                  accordionHeader
                                  accordionBody
                                }
                              }

                              ... on WpModule_Modulefields_slideRepeater_Slide_NestedAccordion {
                                accordionNested {
                                  accordionLevelOneHeader
                                  accordionLevelOneBody
                                  accordionLevelTwoRepeater {
                                    accordionLevelTwoGroup {
                                      accordionLevelTwoHeader
                                      accordionLevelTwoBody
                                      accordionLevelThreeRepeater {
                                        accordionLevelThreeGroup {
                                          accordionLevelThreeHeader
                                          accordionLevelThreeBody
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_Button {
                                buttonGroup {
                                  buttonText
                                  buttonLink
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            symptomManagement {
              fieldGroupName
              stepBlock {
                ... on WpPathway_Pathwayfields_SymptomManagement_stepBlock {
                  modules {
                    module {
                      ... on WpModule {
                        id
                        databaseId
                        uri
                        moduleFields {
                          mediaType
                          prettyTitle
                          slideRepeater {
                            slide {
                              ... on WpModule_Modulefields_slideRepeater_Slide_Header {
                                header
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_TextBlock {
                                textBlock
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_Image {
                                image {
                                  sourceUrl
                                  publicUrl
                                  altText
                                  caption
                                  link
                                }
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_Embed {
                                embed
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_Callout {
                                callout
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_KeyConceptModal {
                                keyConceptModal {
                                  modalHeader
                                  modalBody
                                  modalButtonIcon {
                                    sourceUrl
                                    publicUrl
                                    altText
                                    caption
                                    link
                                  }
                                  modalButtonHeader
                                }
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_Accordion {
                                accordionSingular {
                                  accordionHeader
                                  accordionBody
                                }
                              }

                              ... on WpModule_Modulefields_slideRepeater_Slide_NestedAccordion {
                                accordionNested {
                                  accordionLevelOneHeader
                                  accordionLevelOneBody
                                  accordionLevelTwoRepeater {
                                    accordionLevelTwoGroup {
                                      accordionLevelTwoHeader
                                      accordionLevelTwoBody
                                      accordionLevelThreeRepeater {
                                        accordionLevelThreeGroup {
                                          accordionLevelThreeHeader
                                          accordionLevelThreeBody
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_Button {
                                buttonGroup {
                                  buttonText
                                  buttonLink
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            greatestConcern {
              fieldGroupName
              stepBlock {
                ... on WpPathway_Pathwayfields_GreatestConcern_stepBlock {
                  modules {
                    module {
                      ... on WpModule {
                        id
                        databaseId
                        uri
                        moduleFields {
                          mediaType
                          prettyTitle
                          slideRepeater {
                            slide {
                              ... on WpModule_Modulefields_slideRepeater_Slide_Header {
                                header
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_TextBlock {
                                textBlock
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_Image {
                                image {
                                  sourceUrl
                                  publicUrl
                                  altText
                                  caption
                                  link
                                }
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_Embed {
                                embed
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_Callout {
                                callout
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_KeyConceptModal {
                                keyConceptModal {
                                  modalHeader
                                  modalBody
                                  modalButtonIcon {
                                    sourceUrl
                                    publicUrl
                                    altText
                                    caption
                                    link
                                  }
                                  modalButtonHeader
                                }
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_Accordion {
                                accordionSingular {
                                  accordionHeader
                                  accordionBody
                                }
                              }

                              ... on WpModule_Modulefields_slideRepeater_Slide_NestedAccordion {
                                accordionNested {
                                  accordionLevelOneHeader
                                  accordionLevelOneBody
                                  accordionLevelTwoRepeater {
                                    accordionLevelTwoGroup {
                                      accordionLevelTwoHeader
                                      accordionLevelTwoBody
                                      accordionLevelThreeRepeater {
                                        accordionLevelThreeGroup {
                                          accordionLevelThreeHeader
                                          accordionLevelThreeBody
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_Button {
                                buttonGroup {
                                  buttonText
                                  buttonLink
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            safetyAndAutonomy {
              fieldGroupName
              stepBlock {
                ... on WpPathway_Pathwayfields_SafetyAndAutonomy_stepBlock {
                  modules {
                    module {
                      ... on WpModule {
                        id
                        databaseId
                        uri
                        slug
                      }
                    }
                  }
                }
              }
            }
            qualityOfLife {
              fieldGroupName
              stepBlock {
                ... on WpPathway_Pathwayfields_QualityOfLife_stepBlock {
                  modules {
                    module {
                      ... on WpModule {
                        id
                        databaseId
                        uri
                        moduleFields {
                          mediaType
                          prettyTitle
                          slideRepeater {
                            slide {
                              ... on WpModule_Modulefields_slideRepeater_Slide_Header {
                                header
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_TextBlock {
                                textBlock
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_Image {
                                image {
                                  sourceUrl
                                  publicUrl
                                  altText
                                  caption
                                  link
                                }
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_Embed {
                                embed
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_Callout {
                                callout
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_KeyConceptModal {
                                keyConceptModal {
                                  modalHeader
                                  modalBody
                                  modalButtonIcon {
                                    sourceUrl
                                    publicUrl
                                    altText
                                    caption
                                    link
                                  }
                                  modalButtonHeader
                                }
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_Accordion {
                                accordionSingular {
                                  accordionHeader
                                  accordionBody
                                }
                              }

                              ... on WpModule_Modulefields_slideRepeater_Slide_NestedAccordion {
                                accordionNested {
                                  accordionLevelOneHeader
                                  accordionLevelOneBody
                                  accordionLevelTwoRepeater {
                                    accordionLevelTwoGroup {
                                      accordionLevelTwoHeader
                                      accordionLevelTwoBody
                                      accordionLevelThreeRepeater {
                                        accordionLevelThreeGroup {
                                          accordionLevelThreeHeader
                                          accordionLevelThreeBody
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                              ... on WpModule_Modulefields_slideRepeater_Slide_Button {
                                buttonGroup {
                                  buttonText
                                  buttonLink
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    stepColors: wp {
      acfOptionsCompassusAppSettings {
        globalOptions {
          stepColors {
            totalPainManagement
            symptomManagement
            safetyAndAutonomy
            rightMedsRightTime
            qualityOfLife
            overview
            greatestConcern
          }
        }
      }
    }
  }
`
